    var params;

    // dom ready
    $(function () {

        //if (typeof google !== "undefined"){
        if (document.getElementById("map-canvas")) {
            if (window.google && google.maps) {

                // Map script is already loaded
//            alert("Map script is already loaded. Initialising");
                initializeMap();

            } else {
//            alert("Lazy loading Google map...");
                lazyLoadGoogleMap();
            };
        };
    });

    function initialize(params) {
        var myLatlng = new google.maps.LatLng(59.32522, 18.07002);
        var mapOptions = {
            center: myLatlng,
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{"elementType": "geometry", "stylers": [{"visibility": "simplified"}, {"hue": "#ff0000"}]}, {"featureType": "road", "elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {"featureType": "transit.station", "elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {"featureType": "poi", "stylers": [{"visibility": "off"}]}, {"featureType": "administrative", "stylers": [{"visibility": "simplified"}]}, {"featureType": "water", "stylers": [{"visibility": "off"}]}]
        };
        var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);
        var myimage = '../assets/img/icons/map-marker.png';
        var marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            title: "welcome to our wedding",
            icon: myimage
        });
    }

    function lazyLoadGoogleMap() {
        $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDI_nP4mzaD9r0GafNS0q90X4mfwcfRwU4&callback=initializeMap")
                .done(function (script, textStatus) {
//            alert("Google map script loaded successfully");
                })
                .fail(function (jqxhr, settings, ex) {
//            alert("Could not load Google Map script: " + jqxhr);
                });
    }

    function initializeMap() {
        initialize(params);
    }


