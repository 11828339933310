


$(document).ready(function () {
    $('#home-header-slider').flexslider({
        animation: "fade", //values (fade or slide)
        prevText: " ",
        nextText: " ",
        controlNav: false
    });
    $('#gallery-header-slider').flexslider({
        animation: "fade",
        prevText: " ",
        nextText: " ",
        controlNav: false
    });


});


$(document).ready(function () {
    var countdown = new Countdown({
        selector: '#countdown', //id of the counter container
        msgBefore: "Count down", //message to appear before the counter starts
        msgAfter: "The wedding with great", //message appear after the date passes
        msgPattern: "<span>{days} days</span><span> {hours} hours</span><span> {minutes} minutes</span> <span>{seconds}seconds</span>", //controls the display of the counter
        dateEnd: new Date('Dec 1, 2016 12:00') //Date
    });

});

$(function () {

//back top top button in footer
//maginfic pop up code
    $('.images-parent').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            enabled: true
        },
        // Delay in milliseconds before popup is removed
        removalDelay: 300,
        // Class that is added to popup wrapper and background
        // make it unique to apply your CSS animations just to this exact popup
        mainClass: 'mfp-fade',
        retina: {
            ratio: 1, // Increase this number to enable retina image support.
            // Image in popup will be scaled down by this number.
            // Option can also be a function which should return a number (in case you support multiple ratios). For example:
            // ratio: function() { return window.devicePixelRatio === 1.5 ? 1.5 : 2  }


            replaceSrc: function (item, ratio) {
                return item.src.replace(/\.\w+$/, function (m) {
                    return '@2x' + m;
                });
            } // function that changes image source
        }
    });

});


$(document).ready(function () {
//starting bootstrap scrollsby plugin
    $('body').scrollspy({
        target: '#nav',
        offset: 600
    });
});
//posts slider
$(document).ready(function (ev) {
    $('#custom-carousel').on('slide.bs.carousel', function (evt) {
        $('#custom-carousel .controls li.active').removeClass('active');
        $('#custom-carousel .controls li:eq(' + $(evt.relatedTarget).index() + ')').addClass('active');
    });

});

$(document).ready(function () {

//headroom initiation
    var myElement = document.querySelector(".navbar-fixed-top");
    var headroom = new Headroom(myElement);
    headroom.init();

});
$(document).ready(function () {

    var scrollconfig = {
        move: '10px',
        over: '0.9s',
        wait: '0s',
        easing: 'ease-in-out',
        reset: false,
        delay: 'onload',
        vFactor: 0.2,
        mobile: false,
        duration: 800
    };

    window.sr = new scrollReveal(scrollconfig);
});


$(document).ready(function () {
  //quotes carousel in the 4 index-*.html pages
    var owl = $("#owl-demo");

    owl.owlCarousel({
        singleItem: true,
        autoPlay: true,
        lazyLoad: false,
        autoplayTimeout: 2000, // time in millyseconds 1000=1s
        transitionStyle: "goDown"
    });
    
    //in elem.html
    var owl = $("#owl-thum");

    owl.owlCarousel({
        items: 4,
        autoPlay: true,
        lazyLoad: false,
        autoplayTimeout: 2000, // time in millyseconds 1000=1s
        transitionStyle: "goDown"
    });
    
//slider in blog-slider.html page     
      var sync1 = $("#sync1");
      var sync2 = $("#sync2");
     
      sync1.owlCarousel({
        singleItem : true,
        slideSpeed : 1000,
        navigation: false,
        pagination:false,
        afterAction : syncPosition,
        responsiveRefreshRate : 200
      });
     
      sync2.owlCarousel({
        items : 4,
        itemsDesktop      : [1199,4],
        itemsDesktopSmall     : [979,3],
        itemsTablet       : [768,2],
        itemsMobile       : [479,2],
        pagination:false,
        responsiveRefreshRate : 100,
        autoHeight:true,
        afterInit : function(el){
          el.find(".owl-item").eq(0).addClass("synced");
        }
      });
     
      function syncPosition(el){
        var current = this.currentItem;
        $("#sync2")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced")
        if($("#sync2").data("owlCarousel") !== undefined){
          center(current)
        }
      }
     
      $("#sync2").on("click", ".owl-item", function(e){
        e.preventDefault();
        var number = $(this).data("owlItem");
        sync1.trigger("owl.goTo",number);
      });
     
      function center(number){
        var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
        var num = number;
        var found = false;
        for(var i in sync2visible){
          if(num === sync2visible[i]){
            var found = true;
          }
        }
     
        if(found===false){
          if(num>sync2visible[sync2visible.length-1]){
            sync2.trigger("owl.goTo", num - sync2visible.length+2)
          }else{
            if(num - 1 === -1){
              num = 0;
            }
            sync2.trigger("owl.goTo", num);
          }
        } else if(num === sync2visible[sync2visible.length-1]){
          sync2.trigger("owl.goTo", sync2visible[1])
        } else if(num === sync2visible[0]){
          sync2.trigger("owl.goTo", num-1)
        }
        
      }
     
    });










$('a.page-scroll').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html,body').animate({
                scrollTop: target.offset().top
            }, 1700);
            return false;
        }
    }
});
$(document).ready(function () {
    $(window).stellar();
});

$(".form-control").focus(function () {
    $(this).parent().addClass("isfocus");
});

$(".form-control").focusout(function () {
    $(this).parent().removeClass("isfocus");
});

$(document).ready(function () {
    $('a[href="#"]').click(function (e) {
        e.preventDefault();
    });
});


jQuery(document).ready(function ($) {
    $('#toggle-switcher').click(function () {
        if ($(this).hasClass('opened')) {
            $(this).removeClass('opened');
            $('#style-switcher').animate({'left': '-222px'});
        } else {
            $(this).addClass('opened');
            $('#style-switcher').animate({'left': '-10px'});
        }
    });


    if ($.session.get('theme-cookie')) {
        $('#theme').attr('href', '../assets/css/' + $.session.get('theme-cookie'));
    }


    $('.styleswitch').click(function (e) {
//            alert(this);
        var myhref = $(this).attr('rel');
        $.session.set('theme-cookie', myhref);
        $('#theme').attr('href', '../assets/css/' + $(this).attr('rel'));
        e.preventDefault();
    });


});



$(function () {

    var $container = $('.grid');
    $container.imagesLoaded(function () {
        $container.masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        });
    });
});
