



    $(document).ready(function () {

// validate signup form on keyup and submit
        $("#rsvpform").validate({
            rules: {
                guestname: "required",
                guestemail: {
                    required: true,
                    email: true
                },
                guestcoming: "required",
                events: {
                    required: "#coming:checked",
                    minlength: 1
                },
                guestmessage: "required"

            },
            messages: {
                guestname: "Please enter your name",
                guestemail: {
                    required: "please enter your email",
                    email: "please, enter valid email address"
                },
                guestcoming: "please choose one"
                ,
                events: "Please select at least 1 event",
                guestmessage: "would you leave a message"
            },
            errorPlacement: function (error, element) {
                console.log(element);
                error.insertBefore(element);
            },
            submitHandler: function (form) {
//            alert('submitted');
                $.ajax({
                    type: "POST",
                    url: '../php/contact_me.php',
                    data: $("#rsvpform").serialize(),
                    datatype: "html",
                    success: function (data, textStatus, XHR) {
                        alert('success');
                        console.log(XHR);
//                    $("#inviteModal").html(data);
//                    if (data.indexOf("Thank you") >= 0) {
//                        invites -= 1;
//                        $("#overlay").css("display", "none");
//                        $("#inviteModal").fadeOut(5000);
//                    }
                    }
                });
                return false;
            }
        });
        //code to hide event selection
        var comingchk = $("#coming");
        // comingchk topics are optional, hide at first
        var inital = comingchk.is(":checked");
        var events = $("#wedding_events")[inital ? "removeClass" : "addClass"]("gray");
        var eventInput = events.find("input").attr("disabled", !inital);
        var guestno = $('#no-of-guests').attr("disabled", !inital);
        events.hide();
        guestno.parent(".form-group").hide();
        // show when comingchk is checked
        guestcomingchk = $('input[type=radio][name=guestcoming]');
        guestcomingchk.change(function () {
            if (this.value === 'coming') {
                events.removeClass('gray');
                eventInput.removeAttr('disabled');
                guestno.removeAttr('disabled');
                events.show("slow");
                guestno.parent(".form-group").show("slow");

            } else if (this.value === 'notcoming') {
                events.hide(700);
                guestno.parent(".form-group").hide(700);
                events.addClass('gray');
                eventInput.attr('disabled', 'disabled');
                eventInput.removeAttr('checked');
                $("#events-error").remove();
                guestno.attr('disabled', 'disabled');

            }

        });
    });
   
